<script>
/**
 * Widget Component
 */
export default {
    props:['Cep', 'cidade', 'fornecedor'],
  //  props:{
  //   inicial_prePedido : {type : Object}
  //  },
   mounted(){
     //console.log('estou  no widget')
    //  console.log(this.inicial_prePedido)
    //  this.setar_valores_inicia()
    console.log("esotu no widget Fornecedor")
    console.log(this.fornecedor)
   },
   methods:{
      setar_valores_inicia(){
        if(this.inicial_prePedido.total_pedidos != null ){
          this.statData[2].value = this.inicial_prePedido.total_pedidos
        }else {
          this.statData[2].value = '0,00'
        }
        if(this.inicial_prePedido.data != null){
          this.statData[3].value = this.inicial_prePedido.data
        }else {
          this.statData[3].value = 'Sem data'
        }
        //this.statData[3].value = this.inicial_prePedido.data
        if(this.inicial_prePedido.objeto_fornecedor_id.pes_fantasia != null){
          this.statData[1].value = this.inicial_prePedido.objeto_fornecedor_id.pes_fantasia
        }else {
          this.statData[1].value = 'Sem Fornecedor'
        }
      }
   },
  data() {
    return {
      statData: [
        {
          title: "Cidade",
          icon: "ri-stack-line",
          value: "Selecione",
          subvalue: ""
        },
        {
          title: "Fornecedor",
          icon: "ri-store-2-line",
          value: "Selecione",
          subvalue: ""
        },
        {
          title: "CEP",
          icon: "ri-briefcase-4-line",
          value: "Selecione",
          subvalue: ""
        },
      ]
    };

  },
  watch: {
    Cep(novoCep) {
      this.statData[2].value = novoCep;
      this.statData[2].subvalue = novoCep;
    },
    cidade(novocidade) {
      this.statData[0].value = novocidade;
      this.statData[0].subvalue = novocidade;
    },
    fornecedor(novofornecedor) {
      this.statData[1].value = novofornecedor;
      this.statData[1].subvalue = novofornecedor;
    },
    // pagamento(novoPagamento){
    //   this.statData[2].value = novoPagamento;
    //   this.statData[2].subvalue = novoPagamento;
    // },
    inicial_prePedido(objeto){
      this.statData[2].value = objeto.valor
    }
  }
};
// ola
</script>
<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
              <h4 class="mb-0">{{data.value}}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span class="badge badge-soft-success font-size-11">
              <i class="mdi mdi-menu-up"></i>
              {{data.subvalue}}
            </span>
            <span class="text-muted ml-2">-Informação</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
<style scoped>


  
</style>